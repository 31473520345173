<script lang="ts" setup>
defineEmits<{
  (e: "click"): void;
}>();

const isTouched = ref(false);

const touch = (val: boolean) => {
  isTouched.value = val;
};
</script>

<template>
<button
  class="flex relative h-[50px] w-[54px] items-center justify-center bg-maas-button-primary-background [@media(hover:hover)]:hover:bg-maas-button-primary-hover-background color-maas-button-primary-text tap-highlight-color-transparent cursor-pointer"
  :class="{
    'bg-maas-button-primary-hover-background': isTouched
  }"
  @click="$emit('click')"
  @touchstart.passive="touch(true)"
  @touchend.passive="touch(false)"
>
  <slot/>
</button>
</template>
